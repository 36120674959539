import { VBtn } from 'vuetify/lib/components/VBtn';
import { VCol } from 'vuetify/lib/components/VGrid';
import { VForm } from 'vuetify/lib/components/VForm';
import { VRow } from 'vuetify/lib/components/VGrid';
import { VTextField } from 'vuetify/lib/components/VTextField';
import { VTextarea } from 'vuetify/lib/components/VTextarea';

var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('DialogFormWrapper',{scopedSlots:_vm._u([{key:"form",fn:function(){return [_c(VForm,{ref:"messageDriverForm",staticClass:"custom-form pt-8",attrs:{"id":"messageDriverForm","disabled":!_vm.driver},on:{"submit":function($event){$event.preventDefault();return _vm.messageDriver()}}},[_c(VRow,[_c(VCol,{attrs:{"cols":"12"}},[_c(VTextField,{attrs:{"outlined":"","label":"Kierowca","value":_vm.driver && _vm.driver.fullName,"rules":[_vm.rules.required],"placeholder":"Brak przypisanego kierowcy","disabled":""}})],1),_c(VCol,{attrs:{"cols":"12"}},[_c(VTextarea,{attrs:{"outlined":"","rows":3,"auto-grow":"","label":"Wiadomość","rules":[_vm.rules.required],"placeholder":"Wpisz wiadomość"},model:{value:(_vm.text),callback:function ($$v) {_vm.text=$$v},expression:"text"}})],1)],1)],1)]},proxy:true},{key:"submit",fn:function(){return [_c(VBtn,{staticClass:"base-hover",attrs:{"color":"primary","loading":_vm.isProcessing,"disabled":!_vm.driver,"type":"submit","form":"messageDriverForm"}},[_vm._v(" Wyślij wiadomość ")])]},proxy:true}])})}
var staticRenderFns = []

export { render, staticRenderFns }