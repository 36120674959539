<script>
import { mapActions } from 'vuex'
export default {
  methods: {
    ...mapActions({
      closeDialog: 'layout/closeDialog',
      showSnackbar: 'snackbar/showSnackbar'
    }),
    afterFormSubmitted (message, close = true) {
      this.showSnackbar({
        message: [message],
        type: 'success'
      })
      if (close) this.closeDialog()
    }
  }
}
</script>
