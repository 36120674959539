<template>
  <DialogFormWrapper>
    <template #form>
      <v-form
        id="messageDriverForm"
        ref="messageDriverForm"
        class="custom-form pt-8"
        @submit.prevent="messageDriver()"
        :disabled="!driver"
      >
        <v-row>
          <v-col cols="12">
            <v-text-field
              outlined
              label="Kierowca"
              :value="driver && driver.fullName"
              :rules="[rules.required]"
              placeholder="Brak przypisanego kierowcy"
              disabled
            />
          </v-col>
          <v-col cols="12">
            <v-textarea
              outlined
              :rows="3"
              auto-grow
              label="Wiadomość"
              v-model="text"
              :rules="[rules.required]"
              placeholder="Wpisz wiadomość"
            />
          </v-col>
        </v-row>
      </v-form>
    </template>
    <template #submit>
      <v-btn
        color="primary"
        class="base-hover"
        :loading="isProcessing"
        :disabled="!driver"
        type="submit"
        form="messageDriverForm"
      >
        Wyślij wiadomość
      </v-btn>
    </template>
  </DialogFormWrapper>
</template>

<script>
import DialogFormWrapper from './Partials/DialogFormWrapper'
import { mapState, mapActions } from 'vuex'
import rules from '../../utils/validators'
import afterFormSubmitted from '../../mixins/afterFormSubmitted'
import { Course } from '../../models'

export default {
  components: {
    DialogFormWrapper
  },
  mixins: [afterFormSubmitted],
  data () {
    return {
      text: null,
      rules
    }
  },
  computed: {
    ...mapState({
      dialogItem: state => state.layout.dialog.item,
      isProcessing: state => state.alerts.isProcessing,
    }),
    driver () {
      return this.dialogItem.driver ? this.dialogItem.driver : new Course(this.dialogItem).driver
    }
  },
  methods: {
    ...mapActions({
      createAlert: 'alerts/addNewItem'
    }),
    messageDriver () {
      if (this.$refs.messageDriverForm.validate()) {
        const params = {
          text: this.text,
          driverIds: [this.driver.id],
        }
        this.createAlert({ params })
          .then(() => {
            this.afterFormSubmitted('Wysłano wiadomość')
          })
      }
    }
  }
}
</script>
